<template>
    <div class="manager">
        <header class="manager-header">
            <h1>医院信息平台项目管理服务</h1>
        </header>
        <div class="content">
            <img :src="data.img" alt="">
            <p>{{ data.content }}</p>
        </div>
    </div>
</template>

<script>
    // 医院信息平台项目管理服务

    // vuex
    import { mapMutations } from 'vuex';

    // API
    import { getManager } from '@/api/request';

    export default {
        name: 'manager',
        created () {
            this.getManagerAjax();
        },
        data () {
            return {
                data: ''
            }
        },
        methods: {
            ...mapMutations([
                'setBusinessBanners'
            ]),
            getManagerAjax () {
                getManager().then(res => {
                    this.setBusinessBanners(res.banners);
                    this.data = res.data;
                })
            }
        }
    }
</script>

<style lang="less" scoped>
    .manager {
        .manager-header {
            padding: 20px 40px;
            border-bottom: 1px solid #ccc;
            h1 {
                font-size: 22px;
                font-weight: bold;
            }
        }
        .content {
            padding: 50px 40px;
            p {
                margin-top: 20px;
                font-size: 14px;
                line-height: 1.5;
            }
        }
    }

    @media screen and (max-width: 1023px) {
        .manager {
            .manager-header {
                padding: 0.26666rem 0.53333rem;
                h1 {
                    font-size: 0.48rem;
                }
            }
            .content {
                padding: 0.8rem 0.5333rem;
                p {
                    font-size: 12px;
                    line-height: 2;
                }
            }
        }
    }
</style>